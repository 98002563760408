<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scope>
  .status {
    position: absolute;
    bottom: 10px;
    right: 10px;
    pointer-events: none;
    z-index: 1;
    font-size: 12px;
    color: #ccc;
    transition: opacity .5s;
    opacity: 0;
    &.show {
      opacity: 1;
    }
  }
  .editor {
    position: relative;
    border: 1px solid #ccc;
    z-index:1;
  }
</style>
<template>
  <div class="editor">
    <Toolbar
      v-if="toolbar"
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      style="height: 60vh;min-height: 400px;overflow: hidden;position: relative;"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onEditorCreated"
    />
    <input type="file" ref="file" hidden accept="image/*" />
    <div class="status" :class="{show: loading.load}">
      <div class="msg" v-for="(item, index) in statusList" :key="item.msg + index">{{item.msg}}</div>
    </div>
    <Modal :closable="false" v-model="loading.upload" :loading="loading.upload" :mask-closable="false">
      <div style="display: flex;align-items: center;font-size: 20px;">
        <img src="/static/images/icon/file/loading.gif" style="width: 30px" />
        <span style="margin-left: 10px;">文件上传中</span>
      </div>
      <div slot="footer">
        <Button type="primary" size="large" long :loading="loading.upload">请稍等...</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { fileRequest } from '@/api'
import { createEditor } from '@wangeditor/editor';

function getHtml (content) {
  return createEditor(Array.isArray(content) ? {
    content: content
  } : {
    html: content
  }).getHtml()
}

export default {
  components: { Editor, Toolbar },
  props: {
    readOnly: { type: Boolean, default: false },
    content: { type: [], default: null },
    toolbar: { type: Boolean, default: true }
  },
  data () {
    let resolve = null
    let promise = new Promise(r => resolve = r)
    return {
      // data: null, // 不需要响应式
      editor: null,
      toolbarConfig: {
        excludeKeys: ['uploadVideo', 'insertVideo', 'editVideoSize', 'viewImageLink', 'editImage']
      },
      mode: 'default',
      editorConfig: {
        placeholder: '请输入正文...',
        readOnly: this.readOnly,
        MENU_CONF: {
          uploadImage: {
            customBrowseAndUpload: this.customBrowseAndUpload
          }
        }
      },
      uploadList: [],
      objectUrls: [],
      editorReady: { resolve, promise },
      loading: {
        upload: false,
        load: false
      },
      statusList: []
    }
  },
  watch: {
    async readOnly () {
      await this.editorReady.promise
      this.readOnly ? this.editor.disable() : this.editor.enable()
    },
    content () {
      this.loadContent()
    }
  },
  methods: {
    pushStatus (msg) {
      if (this.statusList.length >= 5) {
        this.statusList.shift()
      }
      this.statusList.push(msg)
    },
    async loadContent () {
      window.test = this
      this.statusList = []
      this.pushStatus({ msg: '解析中...' })
      this.loading.load = true
      if (/^\[|\]$/.test(this.content)) {
        try {
          this.data = JSON.parse(this.content)
        } catch (e) {
          this.data = this.content
        }
      } else {
        this.data = this.content
      }

      await this.editorReady.promise
      process.env.NODE_ENV !== 'production' && console.log('load data', JSON.parse(JSON.stringify(this.data)), getHtml(this.data))
      this.editor.clear()
      this.editor.setHtml(getHtml(this.data))
      this.pushStatus({ msg: '生成内容...' })

      const images = this.editor.getElemsByType('image')
      process.env.NODE_ENV !== 'production' && console.log('img', images)
      if (images.length) {
        this.pushStatus({ msg: '加载图片...' })
        this.editor.disable()
        let flags = {}
        let index = 0
        let loades = images.filter(v => !isNaN(Number(v.href))).map(async item => {
          const fileId = Number(item.href)
          const response = await fileRequest.download({ id: fileId })
          const type = (item.alt || '').split('.').pop().toLowerCase()
          const url = window.URL.createObjectURL(new Blob([response], { type: 'image/' + type }))
          this.objectUrls.push(flags[fileId] = item.src = url)
          this.pushStatus({ msg: '加载图片 ' + (++index) + ' / ' + images.length + ' ...' })
        })

        await Promise.all(loades)
        this.pushStatus({ msg: '生成图片...' })
        this.data.forEach(function each (item) {
          if (item.type === 'image') {
            const fileId = Number(item.href)
            if (!isNaN(fileId) && flags[fileId]) {
              item.src = flags[fileId]
            } else {
              process.env.NODE_ENV !== 'production' && console.warn('err img', item)
            }
          } else if (item.children && item.children.length) {
            item.children.forEach(each)
          }
        })

        this.pushStatus({ msg: '生成内容...' })
        process.env.NODE_ENV !== 'production' && console.log('reload data by img', JSON.parse(JSON.stringify(this.data)), getHtml(this.data))
        this.editor.clear()
        this.editor.setHtml(getHtml(this.data))
        !this.readOnly && this.editor.enable()
      }
      this.pushStatus({ msg: '加载已完成...' })
      this.loading.load = false
    },
    async getJson () {
      this.editor.disable()
      this.loading.upload = true
      const images = this.editor.getElemsByType('image')
      // const container = this.editor.getEditableContainer()
      const loading = '/static/images/icon/file/loading.gif'
      const uploads = images.filter(v => v.href.indexOf('upload-') === 0).map(async img => {
        // const target = container.querySelector('img[data-href="' + img.href + '"]')
        // if (!target) {
        //   process.env.NODE_ENV !== 'production' && console.warn('img[data-href="' + img.href + '"]', 'not found')
        //   return Promise.resolve({ data: false, href: img.href })
        // }
        // target.src = loading
        
        const index = Number(img.href.replace('upload-', ''))
        if (this.uploadList.length <= index) {
          process.env.NODE_ENV !== 'production' && console.warn('upload', index, this.uploadList.length)
          return Promise.resolve({ data: false, href: img.href })
        }
        let parm = new FormData()
        parm.append('file', this.uploadList[index].file)

        const data = await fileRequest.upload(parm)
        return { data, href: img.href }
      })

      const result = await Promise.all(uploads)
      const flags = {}
      result.forEach(v => flags[v.href] = v.data)
      this.uploadList = []

      const nodes = JSON.parse(JSON.stringify(this.editor.children))
      nodes.forEach(function each (item, index, array) {
        if (item.type === 'image') {
          if (item.href.indexOf('upload-') === 0) {
            if (flags[item.href]) {
              item.src = loading
              item.href = flags[item.href].id
            } else {
              array.splice(index, 1)
            }
          } else {
            item.src = loading
          }
        } else if (item.children && item.children.length) {
          item.children.forEach(each)
        }
      })
      !this.readOnly && this.editor.enable()
      this.loading.upload = false

      return nodes
    },
    async customBrowseAndUpload (insertFn) {
      this.$refs.file.onchange = () => {
        if (this.$refs.file.files.length) {
          const file = this.$refs.file.files[0]
          // const reader = new FileReader()
          // reader.onload = (e) => {
          //   this.uploadList.push({ file })
          //   insertFn(e.target.result, file.name, 'upload-' + (this.uploadList.length - 1))
          // }
          // reader.readAsDataURL(file)
          const url = window.URL.createObjectURL(file)
          this.objectUrls.push(url)
          insertFn(url, file.name, 'upload-' + this.uploadList.length)
          this.uploadList.push({ file })
          this.$refs.file.value = null
        }
      }
      this.$refs.file.click()
    },
    onEditorCreated (editor) {
      process.env.NODE_ENV !== 'production' && console.log('editor creadted')
      this.editor = Object.seal(editor)
      this.editorReady.resolve(true)
      this.loadContent()
      window.testeditor = editor
    }
  },
  destroyed () {
    process.env.NODE_ENV !== 'production' && console.log('editor destroyed')
    const editor = this.editor
    if (editor == null) return
    editor.destroy()
    this.objectUrls.forEach(v => window.URL.revokeObjectURL(v))
  }
}
</script>
